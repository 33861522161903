import React, {
  useState,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import styles from "../WinLoseDialog/dialog.module.css";
import { Player } from "@lottiefiles/react-lottie-player";

const winLoseDialog = forwardRef(({ props }, ref) => {
  const dialogRef = useRef(null);
  const playerRef = useRef(null);
  const [dialogConstants, setDialogData] = useState({
    contractWinLoseAmount: 0,
    contractWinLoseState: "",
  });

  useImperativeHandle(ref, () => ({
    openDialog: (state, amount) => {
      setDialogData((previousState) => {
        return {
          ...previousState,
          contractWinLoseAmount: amount,
          contractWinLoseState: state,
        };
      });

      const element = dialogRef.current;
      if (element) {
        element.classList.remove(styles.dialogHide);
        element.classList.add(styles.dialogActive);
      }

      if (state == "win") {
        playerRef.current.play();
      } else {
        playerRef.current.stop();
      }
    },
  }));

  const closeDialog = () => {
    const element = dialogRef.current;

    if (element && element.classList.contains(styles.dialogView)) {
      element.classList.remove(styles.dialogActive);
      element.classList.add(styles.dialogHide);
    }
  };

  return (
    <div
      className={[styles.dialogView, styles.dialogHide].join(" ")}
      ref={dialogRef}
      onClick={() => {
        closeDialog();
      }}
    >
      <div className={[styles.dialogViewContents]}>
        <div>
          <img
            className={[styles.dialogImage]}
            src={
              dialogConstants.contractWinLoseState == "win"
                ? require("./bg.png")
                : require("./bg.png")
            }
            alt="icon"
          />

          <div
            className={[
              styles.dialogViewContentsHolder,
              styles.viewCenter,
            ].join(" ")}
          >
            <img
              className={[styles.dialogWinIcon]}
              src={require(dialogConstants.contractWinLoseState == "win"
                ? "./win_icon.png"
                : "./lose_icon.png")}
              alt="icon"
            />

            <span className={[styles.dialogTitle]}>
              {dialogConstants.contractWinLoseState == "win"
                ? "YOU WIN!"
                : "YOU LOSE!"}
            </span>
            <span className={[styles.playAmountView]}>
              ₹{Number(dialogConstants.contractWinLoseAmount).toLocaleString()}
            </span>
            <span className={[styles.dialogBtnText]}>Close</span>
          </div>
        </div>
      </div>

      <Player
        src={require("../../animations/confetti_anim.json")}
        className={[styles.animationPlayer]}
        loop
        ref={playerRef}
      />
    </div>
  );
});

export default winLoseDialog;
