import React, {
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { getStorage, setStorage } from "../../../modals/Storage";
import { API_ACCESS_URL } from "../../../modals/Constants";
import styles from "../Game.module.css";

const GameBoxesView = forwardRef(
  ({ selected, gameCode, isGameStarted, onGameUpdate, onGameFinish }, ref) => {
    const refs = useRef(new Map());
    const [isBoxClickable, setIsBoxClickable] = useState(true);

    const sleep = (ms) => {
      return new Promise((resolve) => setTimeout(resolve, ms));
    };

    useImperativeHandle(ref, () => ({
      onGameStopExit: () => {
        stopExitGame();
      },
    }));

    const showGameOver = async (status = "lose", amount = 0) => {
      if (status == "lose") {
        await sleep(1500);
      }
      setIsBoxClickable(true);
      onGameFinish(status, amount);
    };

    const stopExitGame = () => {
      async function requestAPI(url, formData) {
        try {
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Route: "route-stopexit-goldminer",
              AuthToken: getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          setIsBoxClickable(true);

          if (data.status_code == "success") {
            if (data["data"].account_balance != "0") {
              setStorage("balance", data["data"].account_balance);
            }

            showGameOver("win", data["data"].match_balance);
          }
        } catch (error) {
          setIsBoxClickable(true);
        }
      }

      const formData = {
        USER_ID: getStorage("uid"),
        MATCH_ID: getStorage("goldminerMatchID"),
      };

      setIsBoxClickable(false);
      requestAPI(API_ACCESS_URL, formData);
    };

    const getBoxInfo = (box, element) => {
      async function requestAPI(url, formData) {
        try {
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Route: "route-openbox-goldminer",
              AuthToken: getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();
          setIsBoxClickable(true);

          if (data.status_code == "success") {
            let matchBalance = data["data"].match_balance;
            let nextWinAmount = data["data"].next_win_amount;
            let nowWinAmount = data["data"].now_win_amount;
            onGameUpdate(matchBalance, nextWinAmount);

            if (data["data"].account_balance != "0") {
              setStorage("balance", data["data"].account_balance);
            }

            const profitAmountView = element.querySelector(
              `.${styles.profitAmountView}`
            );
            if (profitAmountView) {
              profitAmountView.innerHTML = "+" + nowWinAmount;
            }
          }

          if (data["data"].is_mine == "true") {
            showGameOver("lose", data["data"].match_balance);
            element.classList.add(styles.lostBox);
            element.classList.add(styles.notransition);
          } else {
            element.classList.add(styles.wonBox);
            element.classList.add(styles.notransition);
          }
        } catch (error) {
          setIsBoxClickable(true);
        }
      }

      let matchType = "2x2";
      if (selected == 1) {
        matchType = "4x4";
      } else if (selected == 1) {
        matchType = "8x8";
      }

      const formData = {
        USER_ID: getStorage("uid"),
        BOX_OPEN: box,
        MATCH_ID: getStorage("goldminerMatchID"),
        MATCH_TYPE: matchType,
      };

      setIsBoxClickable(false);
      requestAPI(API_ACCESS_URL, formData);
    };

    const openBox = (index) => {
      if (!isGameStarted || !isBoxClickable) {
        return;
      }

      const element = refs.current.get(index);
      if (
        element &&
        !element.classList.contains(styles.wonBox) &&
        !element.classList.contains(styles.lostBox)
      ) {
        getBoxInfo(index, element);
      }
    };

    const gameBoxView = (index) => {
      return (
        <div
          className={[
            styles.box,
            styles.viewCenter,
            isGameStarted ? styles.scaleAnimation : "",
          ].join(" ")}
          ref={(el) => {
            if (el) {
              refs.current.set(index, el);
            } else {
              refs.current.delete(index);
            }
          }}
          onClick={() => openBox(index)}
        >
          <div className={[styles.profitBox].join(" ")}>
            <img src={require("../icons/10.png")} alt="icon" />

            <p className={[styles.profitAmountView]}></p>
          </div>

          <div className={[styles.minesBox]}>
            <img
              className={[styles.ringBellAnimation]}
              src={require("../icons/4.png")}
              alt="icon"
            />
          </div>
        </div>
      );
    };

    return (
      <div>
        {selected == 0 ? (
          <>
            <div className={[styles.boxHolder, styles.boxCount2].join(" ")}>
              {gameBoxView(0)}
              {gameBoxView(1)}
            </div>

            <div className={[styles.boxHolder, styles.boxCount2].join(" ")}>
              {gameBoxView(2)}
              {gameBoxView(3)}
            </div>
          </>
        ) : selected == 1 ? (
          <>
            <div className={[styles.boxHolder, styles.boxCount4].join(" ")}>
              {gameBoxView(0)}
              {gameBoxView(1)}
              {gameBoxView(2)}
              {gameBoxView(3)}
            </div>

            <div className={[styles.boxHolder, styles.boxCount4].join(" ")}>
              {gameBoxView(4)}
              {gameBoxView(5)}
              {gameBoxView(6)}
              {gameBoxView(7)}
            </div>

            <div className={[styles.boxHolder, styles.boxCount4].join(" ")}>
              {gameBoxView(8)}
              {gameBoxView(9)}
              {gameBoxView(10)}
              {gameBoxView(11)}
            </div>

            <div className={[styles.boxHolder, styles.boxCount4].join(" ")}>
              {gameBoxView(12)}
              {gameBoxView(13)}
              {gameBoxView(14)}
              {gameBoxView(15)}
            </div>
          </>
        ) : (
          <>
            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(0)}
              {gameBoxView(1)}
              {gameBoxView(2)}
              {gameBoxView(3)}
              {gameBoxView(4)}
              {gameBoxView(5)}
              {gameBoxView(6)}
              {gameBoxView(7)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(8)}
              {gameBoxView(9)}
              {gameBoxView(10)}
              {gameBoxView(11)}
              {gameBoxView(12)}
              {gameBoxView(13)}
              {gameBoxView(14)}
              {gameBoxView(15)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(16)}
              {gameBoxView(17)}
              {gameBoxView(18)}
              {gameBoxView(19)}
              {gameBoxView(20)}
              {gameBoxView(21)}
              {gameBoxView(22)}
              {gameBoxView(23)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(24)}
              {gameBoxView(25)}
              {gameBoxView(26)}
              {gameBoxView(27)}
              {gameBoxView(28)}
              {gameBoxView(29)}
              {gameBoxView(30)}
              {gameBoxView(31)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(32)}
              {gameBoxView(33)}
              {gameBoxView(34)}
              {gameBoxView(35)}
              {gameBoxView(36)}
              {gameBoxView(37)}
              {gameBoxView(38)}
              {gameBoxView(39)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(40)}
              {gameBoxView(41)}
              {gameBoxView(42)}
              {gameBoxView(43)}
              {gameBoxView(44)}
              {gameBoxView(45)}
              {gameBoxView(46)}
              {gameBoxView(47)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(48)}
              {gameBoxView(49)}
              {gameBoxView(50)}
              {gameBoxView(51)}
              {gameBoxView(52)}
              {gameBoxView(53)}
              {gameBoxView(54)}
              {gameBoxView(55)}
            </div>

            <div className={[styles.boxHolder].join(" ")}>
              {gameBoxView(56)}
              {gameBoxView(57)}
              {gameBoxView(58)}
              {gameBoxView(59)}
              {gameBoxView(60)}
              {gameBoxView(61)}
              {gameBoxView(62)}
              {gameBoxView(63)}
            </div>
          </>
        )}
      </div>
    );
  }
);

export default GameBoxesView;
