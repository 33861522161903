import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
  useState,
} from "react";
import { Link } from "react-router-dom";
import styles from "../Game.module.css";
import { getStorage, setStorage } from "../../../modals/Storage";
import { API_ACCESS_URL } from "../../../modals/Constants";

const BettingDialog = forwardRef(
  ({ dialogData, onBetCompleteListener }, ref) => {
    const dialogRef = useRef(new Map());
    const [isLoadingShow, setIsLoadingShow] = useState(false);

    const [dialogConstants, setDialogData] = useState({
      contractAmnt: 10,
      contracTotalAmnt: 10,
      contractNumLot: 1,
      contractSelected: "",
      contractGameCode: "",
    });

    useImperativeHandle(ref, () => ({
      openDialog: (gameCode = "", data = "") => {
        if (gameCode != "" && data != "") {
          setDialogData((previousState) => {
            return {
              ...previousState,
              contractSelected: data,
              contractGameCode: gameCode,
            };
          });
        }
        const element = dialogRef.current;
        if (element) {
          element.classList.remove(styles.bettingDialogHide);
          element.classList.remove(styles.bettingDialogClose);
          element.classList.add(styles.bettingDialogActive);
        }
      },
    }));

    const updateContractAmount = (amount) => {
      setDialogData((previousState) => {
        return {
          ...previousState,
          contractAmnt: amount,
        };
      });

      updateTotalContractAmnt(amount, dialogConstants.contractNumLot);
    };

    const updateTotalContractAmnt = (lotSize, contractAmnt) => {
      let updatedContractAmount = lotSize * contractAmnt;

      setDialogData((previousState) => {
        return { ...previousState, contracTotalAmnt: updatedContractAmount };
      });
    };

    const updateLotOnInputChange = (data) => {
      let updatedData = Number(data);

      if (updatedData >= 0 && updatedData < 1000) {
        updateTotalContractAmnt(updatedData, dialogConstants.contractAmnt);

        setDialogData((previousState) => {
          return { ...previousState, contractNumLot: updatedData };
        });
      }
    };

    const updateLotData = (data) => {
      let updatedData = dialogConstants.contractNumLot + Number(data);

      if (updatedData >= 1 && updatedData < 200) {
        updateTotalContractAmnt(updatedData, dialogConstants.contractAmnt);

        setDialogData((previousState) => {
          return { ...previousState, contractNumLot: updatedData };
        });
      }
    };

    const confirmMatch= (dialogData) => {
      async function requestAPI(url, formData) {
        try {
          const res = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Route: dialogData.gameBetRoutePath,
              AuthToken: getStorage("secret"),
            },
            body: JSON.stringify(formData),
          });

          const data = await res.json();

          closeDialog();

          if (data.status_code == "success") {
            setStorage("goldminerMatchID", data["data"].match_order_id);
            setStorage("balance", data["data"].account_balance, 30);
            onBetCompleteListener(
              "success",
              "Bet Success",
              data["data"].next_win_amount
            );
          } else if (data.status_code == "balance_error") {
            onBetCompleteListener(
              "failed",
              "Your balance is insufficient ! Please recharge now !"
            );
          } else if (data.status_code == "account_error") {
            onBetCompleteListener(
              "failed",
              "Account Problem ! Please try again!"
            );
          } else if (data.status_code == "betting_timeout_error") {
            onBetCompleteListener(
              "failed",
              "Oops! Betting timeout! Please try again!"
            );
          } else if (data.status_code == "auth_error") {
            onBetCompleteListener(
              "failed",
              "Authentication Error! Please Login Again"
            );
          } else {
            onBetCompleteListener(
              "failed",
              "There was a technical issue! Please try again!"
            );
          }

          setIsLoadingShow(false);
        } catch (error) {
          console.log(error);

          setIsLoadingShow(false);
          closeDialog();
          onBetCompleteListener(
            "failed",
            "There was a technical issue! Please try again!"
          );
        }
      }

      if (dialogConstants.contractNumLot > 0) {
        const formData = {
          USER_ID: getStorage("uid"),
          NUM_LOT: dialogConstants.contractNumLot,
          CONTRACT_AMOUNT: dialogConstants.contractAmnt,
        };
        setIsLoadingShow(true);
        requestAPI(API_ACCESS_URL, formData);
      }
    };

    const closeDialog = () => {
      const element = dialogRef.current;

      if (element && element.classList.contains(styles.bettingDialog)) {
        element.classList.remove(styles.bettingDialogActive);
        element.classList.add(styles.bettingDialogClose);
      }
    };

    const handleAnimationEnd = () => {
      const element = dialogRef.current;
      if (element && element.classList.contains(styles.bettingDialogClose)) {
        element.classList.remove(styles.bettingDialogClose);
        element.classList.add(styles.bettingDialogHide);
      }
    };

    const handleChildClick = (event) => {
      event.stopPropagation(); // This stops the click event from bubbling up to the parent
    };

    return (
      <div
        className={[
          styles.bettingDialog,
          styles.respWidth,
          styles.bettingDialogHide,
        ].join(" ")}
        onClick={() => {
          closeDialog();
        }}
        ref={dialogRef}
        onAnimationEnd={handleAnimationEnd}
      >
        <div
          className={[styles.bettingDialogContents, styles.respWidth].join(" ")}
          onClick={handleChildClick}
        >
          <span>Balance</span>
          <div className={[styles.pointView]}>
            <span>{getStorage("balance")}</span>
            <Link className={[styles.btn]} to={"/recharge"}>Recharge</Link>
          </div>
          <br></br>
          <span>choose amount</span>
          <div className={[styles.chooseAmountsView]}>
            <div
              className={[styles.btn]}
              onClick={() =>
                updateContractAmount(`${dialogData.bettingMinBetAmount}`)
              }
            >
              {dialogData.bettingMinBetAmount}
            </div>
            <div
              className={[styles.btn]}
              onClick={() => updateContractAmount("50")}
            >
              50
            </div>
            <div
              className={[styles.btn]}
              onClick={() => updateContractAmount("100")}
            >
              100
            </div>
            <div
              className={[styles.btn]}
              onClick={() => updateContractAmount("1000")}
            >
              1000
            </div>
          </div>

          <div className={[styles.updateQuantityView]}>
            <div onClick={() => updateLotData("-1")}>-</div>
            <input
              type="number"
              value={
                dialogConstants.contractNumLot > 0
                  ? dialogConstants.contractNumLot
                  : ""
              }
              autoComplete="off"
              onChange={(e) => updateLotOnInputChange(e.target.value)}
            ></input>

            <div onClick={() => updateLotData("+1")}>+</div>
          </div>

          <div className="mg-t-20">
            <p>Total contract money is ₹{dialogConstants.contracTotalAmnt}</p>

            <div className={[styles.controlOptions]}>
              <div
                className={[styles.btn]}
                onClick={() => {
                  closeDialog();
                }}
              >
                <span>Cancel</span>
              </div>

              <div
                className={[styles.btn, styles.viewCenter].join(" ")}
                onClick={() => confirmMatch(dialogData)}
              >
                <div
                  className={[
                    styles.circleLoadView,
                    styles.hW25px,
                    !isLoadingShow ? styles.hideCircleLoadView : "",
                  ].join(" ")}
                ></div>
                <span className={[isLoadingShow ? styles.hideView : ""]}>
                  Continue
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default BettingDialog;
