import React, { useRef, useCallback, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BettingDialog from "./dialogs/BettingDialog";
import LoadingDialog from "../../dialogs/LoadingDialog";
import ToastDialog from "../../dialogs/ToastDialog/toast";
import WinDialog from "./dialogs/WinLoseDialog/dialog";
import RuleDialog from "./dialogs/RuleDialog/dialog";
import { API_ACCESS_URL } from "../../modals/Constants";
import GameView from "./components/GameView";
import styles from "./Game.module.css";
import { setStorage, getStorage } from "../../modals/Storage";

function GoldMiner() {
  const navigate = useNavigate();
  const bettingDialogRef = useRef(null);
  const winLoseDialogRef = useRef(null);
  const toastDialogRef = useRef(null);
  const ruleDialogRef = useRef(null);

  const gameBoxesRef = useRef(null);
  const intervalRef = useRef(null);

  const [tabSelected, setTabSelected] = useState(0);

  const [gameWinBalance, setGameWinBalance] = useState(0);
  const [gameNextWin, setGameNextWin] = useState(0);
  const [gameSelected, setGameSelected] = useState(1);
  const [isGameStarted, setGameStarted] = useState(false);

  const [selectedRecord, setSelectedRecord] = useState(0);

  const [isGameLoaded, setIsGameLoaded] = useState("");

  const [pageConst, setGameData] = useState({
    pageTitle: "Gold Miner",
    gameCode: "GOLDMINER",
    isLoadingShow: false,
    ruleDialogShow: false,
    winDialogShow: true,
    constWinAmount: 100,
    gameWinDetails: [],
    gameRecordsList: [],
    mygameRecordsList: [],
    everyOnesOrderList: [],
  });

  const [bettingDialogConst, setBettingDialogConst] = useState({
    bettingMinBetAmount: 10,
    gameBetRoutePath: "route-bet-goldminer",
  });

  const handleVisibilityChange = useCallback(() => {
    let isVisible = document.visibilityState === "visible";
    if (isVisible) {
      // initializeGame(false);
    }
  }, []);

  const updateSelectedRecord = (data) => {
    if (selectedRecord != 0 && selectedRecord == data) {
      setSelectedRecord(0);
    } else {
      setSelectedRecord(data);
    }
  };

  const updateMyBetRecords = (recentBetData) => {
    let tempData = [];
    const recentBetArr = recentBetData.split(",");

    tempData.push(
      <div key={32} className="row-view mg-t-15 sb-view">
        <span className="ft-sz-14">{recentBetArr[1]}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}>
          {getCapitalLetter(recentBetArr[2])}
        </span>
        <span className="ft-sz-15">{recentBetArr[3] + ".00"}</span>
        <span className={`ft-sz-17 h-w-28 br-50 cl-white v-center`}></span>
        <span className={`w-65-p ft-sz-15 txt-a-end`}></span>
      </div>
    );

    let finalData = [tempData].concat(pageConst.mygameRecordsList);

    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: finalData };
    });
  };

  const updateActiveTab = (data) => {
    setTabSelected(data);
  };

  const updateLoadingStatus = (data) => {
    setGameData((previousState) => {
      return { ...previousState, isLoadingShow: data };
    });
  };

  const showToast = (message, duration, delay) => {
    toastDialogRef.current.openDialog(message, duration, delay);
  };

  const showRuleDialog = () => {
    if (ruleDialogRef.current) {
      ruleDialogRef.current.openDialog();
    }
  };

  const showBettingDialog = (selected = "") => {
    if (
      bettingDialogRef.current &&
      selected != "" &&
      pageConst.gameCode != ""
    ) {
      bettingDialogRef.current.openDialog(pageConst.gameCode, selected);
    }
  };

  const onGameUpdate = (balance = 0, nextWinAmount = 0) => {
    setGameWinBalance(balance);
    setGameNextWin(nextWinAmount);
  };

  const onGameFinish = (state = "", amount = 0) => {
    if (winLoseDialogRef.current && state != "") {
      winLoseDialogRef.current.openDialog(state, amount);
      setGameStarted(false);
    }
  };

  const onBetCompleteListener = (status, message = "", extra = "") => {
    if (status == "failed") {
      showToast(message, 2500, 300);
    } else if (status == "success") {
      setGameStarted(true);
      setGameNextWin(extra);
    }
  };

  const onGameStopExit = () => {
    if (gameBoxesRef.current) {
      gameBoxesRef.current.onGameStopExit();
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  const onToastChange = (data, msg) => {};

  const getCapitalLetter = (data) => {
    let returnVal = "";

    if (data == "red") {
      returnVal = "R";
    } else if (data == "green") {
      returnVal = "G";
    } else if (data == "violet") {
      returnVal = "V";
    } else {
      returnVal = data;
    }

    return returnVal;
  };

  const updateMyGameRecords = (data) => {
    setGameData((previousState) => {
      return { ...previousState, mygameRecordsList: data };
    });
  };

  const getRandBool = () => {
    let returnVal = false;

    let status = Math.round(Math.random());
    if (status == 1) {
      returnVal = true;
    }

    return returnVal;
  };

  const getRandomNum = (min, max, type) => {
    if (type == "multi") {
      return Math.round((Math.random() * (max - min) + min) / 10) * 10;
    } else {
      return Math.floor(Math.random() * (max - min) + min);
    }
  };

  const updateEveryOnesOrders = (everyoneorder) => {
    let tempData = [];

    if (getRandBool()) {
      if (everyoneorder.length >= 10) {
        everyoneorder.pop();
        tempData = everyoneorder;
      } else if (everyoneorder.length > 0) {
        tempData = everyoneorder;
      }

      let randBetId = getRandomNum(100, 999, "");
      let randBetAmnt = 10;

      if (getRandBool()) {
        randBetAmnt = getRandomNum(10, 300, "multi");
      } else {
        randBetAmnt = getRandomNum(10, 2000, "multi");
      }

      let randPlayType = "";

      if (getRandBool()) {
        randPlayType = "2x2";
      } else if (getRandBool()) {
        randPlayType = "4x4";
      } else {
        randPlayType = "8x8";
      }

      tempData.unshift(
        <div
          key={randBetId * randBetAmnt}
          className={[styles.rowView, styles.jcSpaceBet, styles.pd12px0px].join(
            " "
          )}
        >
          <span
            className={[styles.fontS15Px, styles.colorLightWhite].join(" ")}
          >
            ***{randBetId}
          </span>
          <span
            className={[
              styles.hW25px,
              styles.fontS15Px,
              styles.colorLightWhite,
            ].join(" ")}
          >
            {randPlayType}
          </span>
          <span
            className={[
              styles.fontS15Px,
              styles.colorGreen,
              styles.w50px,
              styles.textAlgEnd,
            ].join(" ")}
          >
            ₹{randBetAmnt}
          </span>
        </div>
      );

      setGameData((previousState) => {
        return { ...previousState, everyOnesOrderList: tempData };
      });
    }
  };

  function initializeGame() {
    async function requestAPI(url, formData) {
      try {
        const res = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Route: "route-initialize-goldminer",
            AuthToken: getStorage("secret"),
          },
          body: JSON.stringify(formData),
        });

        const data = await res.json();
        updateLoadingStatus(false);

        if (data.status_code == "success") {
          if (data.account_balance != "0") {
            setStorage("balance", data.account_balance);
          }

          updateMyGameRecords(data.match_played_records);
        } else if (data.status_code == "balance_error") {
          showToast(
            "Your balance is insufficient ! Please recharge now !",
            2500
          );
        } else if (data.status_code == "account_error") {
          showToast("Account Problem ! Please try again!", 2500);
        } else if (data.status_code == "auth_error") {
          showToast("Authentication Error! Please Login Again !", 2500);
        } else {
          showToast("There was a technical issue! Please try again!", 2500);
        }
      } catch (error) {
        updateLoadingStatus(false);
        showToast("There was a technical issue! Please try again!", 2500);
      }
    }

    if (!isGameLoaded) {
      updateLoadingStatus(true);
      setIsGameLoaded(true);

      const formData = {
        USER_ID: getStorage("uid"),
      };

      requestAPI(API_ACCESS_URL, formData);
    }
  }

  const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  const chooseGame = (selected) => {
    if (!isGameStarted) {
      setGameSelected(selected);
    }
  };

  const startGame = () => {
    showBettingDialog("start");
  };

  useEffect(() => {
    document.addEventListener("visibilitychange", handleVisibilityChange);

    initializeGame();

    const updateEveryOneData = () => {
      updateEveryOnesOrders(pageConst.everyOnesOrderList);
    };

    intervalRef.current = setInterval(() => {
      // requestAnimationFrame(updateValue);
      requestAnimationFrame(updateEveryOneData);
    }, 1000); // Update every second

    //Clearing the interval
    return () => {
      clearInterval(intervalRef.current);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [pageConst.everyOnesOrderList]);

  return (
    <div className={[styles.viewCenter, styles.gameBg].join(" ")}>
      <div
        className={[
          styles.h100vh,
          styles.posRelative,
          styles.respWidth,
          styles.overflowYScroll,
          styles.hideScrollBar,
        ].join(" ")}
      >
        {/* <TopBar intentData={pageConst} multiBtn={true} multiBtn1="" multiBtn2="Rule" updateState={topBarClickAction}/>
           <LoadingDialog intentData={pageConst}/>*/}

        <RuleDialog ref={ruleDialogRef} />
        <ToastDialog onToastChange={onToastChange} ref={toastDialogRef} />
        <WinDialog ref={winLoseDialogRef} />

        <BettingDialog
          dialogData={bettingDialogConst}
          onBetCompleteListener={onBetCompleteListener}
          updateMyBetRecords={updateMyBetRecords}
          ref={bettingDialogRef}
        />

        <div className={[styles.gameTopBar, styles.respWidth].join(" ")}>
          <img
            onClick={() => goBack()}
            src={require("../GoldMiner/icons/angle_left_icon.png")}
            alt="icon"
          />
          <span className={[styles.gameTopBarTitle]}>
            {pageConst.pageTitle}
          </span>
          <span 
          className={[styles.pd5px]}
          onClick={() => showRuleDialog()}>Rule</span>
        </div>

        <div
          className={[styles.gameView, styles.respWidth, styles.colView].join(
            " "
          )}
        >
          <div className={[styles.pd10px].join(" ")}>
            <div
              className={[
                styles.bgGameStage,
                styles.w100,
                styles.borderRadius10px,
                styles.overflowHidden,
              ].join(" ")}
            >
              <div className={[styles.pd10px]}>
                <div className={[styles.rowView]}>
                  <div
                    className={[
                      styles.gameOptionBtn,
                      styles.viewCenter,
                      gameSelected == 0 ? styles.bgActiveGameOption : "",
                    ].join(" ")}
                    onClick={() => chooseGame(0)}
                  >
                    2x2
                  </div>
                  <div
                    className={[
                      styles.gameOptionBtn,
                      styles.viewCenter,
                      gameSelected == 1 ? styles.bgActiveGameOption : "",
                    ].join(" ")}
                    onClick={() => chooseGame(1)}
                  >
                    4x4
                  </div>
                  <div
                    className={[
                      styles.gameOptionBtn,
                      styles.viewCenter,
                      gameSelected == 2 ? styles.bgActiveGameOption : "",
                    ].join(" ")}
                    onClick={() => chooseGame(2)}
                  >
                    8x8
                  </div>
                </div>

                <div className={[styles.gameBoxContainer].join(" ")}>
                  <div
                    className={[
                      styles.startPlayBtn,
                      styles.viewCenter,
                      isGameStarted ? styles.hideView : "",
                    ].join(" ")}
                    onClick={() => startGame()}
                  >
                    <img
                      className={[styles.scaleAnimation]}
                      src={require("../GoldMiner/icons/6.png")}
                      alt="icon"
                    />
                  </div>
                  <GameView
                    selected={gameSelected}
                    gameCode={pageConst.gameCode}
                    isGameStarted={isGameStarted}
                    onGameUpdate={onGameUpdate}
                    onGameFinish={onGameFinish}
                    ref={gameBoxesRef}
                  />
                </div>
              </div>

              <div
                className={[
                  styles.w100,
                  styles.pd15px,
                  styles.rowView,
                  styles.jcSpaceBet,
                  styles.bgDarkYellow,
                  !isGameStarted ? styles.hideView : "",
                ].join(" ")}
              >
                <div className={[styles.rowView]}>
                  <div className={[styles.colView]}>
                    <div className={[styles.viewCenter]}>
                      <img
                        className={[styles.w15px].join(" ")}
                        src={require("../GoldMiner/icons/10.png")}
                        alt="icon"
                      />
                      <span className={[styles.fontS14Px]}>bonus:</span>
                    </div>
                    <span
                      className={[styles.fontS16Px, styles.fontW500].join(" ")}
                    >
                      +{gameWinBalance}
                    </span>
                  </div>

                  <div className={[styles.colView, styles.mgL15px].join(" ")}>
                    <div className={[styles.viewCenter]}>
                      <img
                        className={[styles.w15px].join(" ")}
                        src={require("../GoldMiner/icons/10.png")}
                        alt="icon"
                      />
                      <span className={[styles.fontS14Px]}>Next:</span>
                    </div>
                    <span
                      className={[styles.fontS16Px, styles.fontW500].join(" ")}
                    >
                      +{gameNextWin}
                    </span>
                  </div>
                </div>

                <div>
                  <img
                    className={[styles.stopExitBtn]}
                    src={require("./icons/13.png")}
                    alt="icon"
                    onClick={() => onGameStopExit()}
                  />
                </div>
              </div>

              <div
                className={[
                  styles.fontW500,
                  styles.pd15px,
                  styles.bgDarkYellow,
                  isGameStarted ? styles.hideView : "",
                ].join(" ")}
              >
                Check the boxes that you think have no mines to get a bonus !
              </div>
            </div>
          </div>

          <div
            className={[styles.colView, styles.pd10px, styles.mgB15px].join(
              " "
            )}
          >
            <div
              className={[styles.borderRadius15px, styles.bgDarkGrey].join(" ")}
            >
              <div className={[styles.tabView]}>
                <div
                  className={[
                    styles.viewCenter,
                    tabSelected == 0 ? styles.tabViewActive : "",
                  ].join(" ")}
                  onClick={() => updateActiveTab(0)}
                >
                  Everyone's Order
                </div>

                <div
                  className={[
                    styles.viewCenter,
                    tabSelected == 1 ? styles.tabViewActive : "",
                  ].join(" ")}
                  onClick={() => updateActiveTab(1)}
                >
                  My Order
                </div>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.pd15px,
                  styles.minH450px,
                  tabSelected != 0 ? styles.hideView : "",
                ].join(" ")}
              >
                <div
                  className={[
                    styles.rowView,
                    styles.jcSpaceBet,
                    styles.fontS14Px,
                    styles.colorYellow,
                  ].join(" ")}
                >
                  <span>User</span>
                  <span>Type</span>
                  <span>Point</span>
                </div>

                <div
                  className={[
                    styles.everyOneOrdersView,
                    styles.colView,
                    styles.fadeInAnimation,
                    styles.mgT15px,
                  ].join(" ")}
                >
                  {pageConst.everyOnesOrderList}
                </div>
              </div>

              <div
                className={[
                  styles.colView,
                  styles.pd15px,
                  styles.minH450px,
                  tabSelected != 1 ? styles.hideView : "",
                ].join(" ")}
              >
                <div className={[styles.myGameRecordsView]}>
                  {pageConst.mygameRecordsList.map((data, index) => (
                    <div
                      key={data["m_order_id"]}
                      className={[styles.myGameRecordsViewItem].join(" ")}
                    >
                      <div
                        className={[
                          styles.myGameRecordsContents,
                          styles.rowView,
                        ].join(" ")}
                        onClick={() => updateSelectedRecord(data["m_order_id"])}
                      >
                        <div
                          className={[
                            styles.viewCenter,
                            styles.resultShortView,
                          ].join(" ")}
                        >
                          <img
                            src={require(data["m_status"] == "profit"
                              ? "./icons/10.png"
                              : "./icons/4.png")}
                            alt="icon"
                          />
                        </div>

                        <div
                          className={[
                            styles.colView,
                            styles.mygameShortDetailsView,
                          ].join(" ")}
                        >
                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                            ].join(" ")}
                          >
                            <span
                              className={[
                                styles.fontS15Px,
                                styles.colorLightWhite,
                                styles.letterSpacing1p1px,
                              ].join(" ")}
                            >
                              {data["m_period_id"]}
                            </span>

                            <span
                              className={[
                                styles.fontS18Px,
                                data.m_status == "profit"
                                  ? styles.colorGreen
                                  : styles.colorRed,
                              ].join(" ")}
                            >
                              {data.m_status == "profit"
                                ? "₹" + data.m_profit
                                : data.m_status == "loss"
                                ? "-₹" + data.m_cost
                                : ""}
                            </span>
                          </div>

                          <div
                            className={[
                              styles.w100,
                              styles.rowView,
                              styles.jcSpaceBet,
                              styles.mgT5px,
                            ].join(" ")}
                          >
                            <span
                              className={[
                                styles.colorLightWhite,
                                styles.fontS13Px,
                              ].join(" ")}
                            >
                              {data["m_time_stamp"]}
                            </span>
                            <span
                              className={[
                                styles.colorLightWhite,
                                styles.fontS12Px,
                              ].join(" ")}
                            >
                              Fee: ₹{data.m_fee}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div
                        className={[
                          styles.mygameRecordsDetails,
                          styles.colorLightWhite,
                          data["m_order_id"] != selectedRecord
                            ? styles.hideView
                            : "",
                        ].join(" ")}
                      >
                        <span className={[styles.mygameRecordsDetailsTitle]}>
                          Details
                        </span>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT10px,
                          ].join(" ")}
                        >
                          <span>Order Id</span>
                          <span>{data["m_order_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Match ID</span>
                          <span>{data["m_period_id"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Amount</span>
                          <span>₹{data["m_cost"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Quantity</span>
                          <span>{data["m_color_lot"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Tax & Fee</span>
                          <span className={[styles.colorRed]}>
                            ₹{data["m_fee"]}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Box Opened</span>
                          <span>{data["m_result"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Status</span>
                          <span>{data["m_status"]}</span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Win/Loss</span>
                          <span
                            className={[
                              data.m_status == "profit"
                                ? styles.colorGreen
                                : styles.colorRed,
                            ]}
                          >
                            {data.m_status == "profit"
                              ? "₹" + data.m_profit
                              : data.m_status == "loss"
                              ? "-₹" + data.m_invested
                              : ""}
                          </span>
                        </div>

                        <div
                          className={[
                            styles.w100,
                            styles.rowView,
                            styles.jcSpaceBet,
                            styles.mgT5px,
                          ].join(" ")}
                        >
                          <span>Order Time</span>
                          <span>{data["m_time_stamp"]}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                <div
                  className={[
                    styles.w100,
                    styles.viewCenter,
                    styles.mgT15px,
                  ].join(" ")}
                >
                  <Link
                    className={[
                      styles.viewCenter,
                      styles.pd5px25px,
                      styles.fontS16Px,
                      styles.colorWhite,
                      styles.textDecoNone,
                      styles.borderRadius15px,
                      styles.bgLightWhite,
                    ].join(" ")}
                    to={"/myorders"}
                  >
                    Show More &#8250;
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GoldMiner;
