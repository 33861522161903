import React, { useImperativeHandle, forwardRef, useRef } from "react";
import styles from "./dialog.module.css";

const RuleDialog = forwardRef(({}, ref) => {
  const dialogRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openDialog: () => {
      const element = dialogRef.current;
      if (element) {
        element.classList.remove(styles.dialogHide);
        element.classList.remove(styles.dialogClose);
        element.classList.add(styles.dialogActive);
      }
    },
  }));

  const closeDialog = () => {
    const element = dialogRef.current;

    if (element && element.classList.contains(styles.dialogActive)) {
      element.classList.remove(styles.dialogActive);
      element.classList.add(styles.dialogClose);
    }
  };

  const handleAnimationEnd = () => {
    const element = dialogRef.current;
    if (element && element.classList.contains(styles.dialogClose)) {
      element.classList.remove(styles.dialogClose);
      element.classList.add(styles.dialogHide);
    }
  };

  const handleChildClick = (event) => {
    event.stopPropagation();
  };

  return (
    <div
      className={[styles.dialogView, styles.dialogHide].join(" ")}
      onClick={() => closeDialog()}
      onAnimationEnd={handleAnimationEnd}
      ref={dialogRef}
    >
      <div
        className={[styles.dialogContents].join(" ")}
        onClick={handleChildClick}
      >
        <div className={[styles.dialogContentsHolder].join(" ")}>
          <span className={[styles.headLine].join(" ")}>GoldMiner Rules:</span>

          <div className={[styles.paraGraph, styles.mgT15px].join(" ")}>
            You can choose 2x2, 4x4, 8x8 three ways to play. The number of
            levels and reward amounts for each method are different.
          </div>

          <img
            className={[styles.media, styles.mgT15px].join(" ")}
            src={require("../../icons/15.png")}
            alt="icon"
          />

          <div className={[styles.paraGraph, styles.mgT15px].join(" ")}>
            The following is an example of 4x4: In the 16 boxes, one of them was
            buried with a mine. The goal of the game is to find a box without
            mines, and every time you find one, you will get a reward.
          </div>
        </div>

        <div className={[styles.dismissBtn]} onClick={() => closeDialog()}>
          <span>Close</span>
        </div>
      </div>
    </div>
  );
});

export default RuleDialog;
