import React, {
  useImperativeHandle,
  forwardRef,
  useRef,
  useState,
  useEffect,
} from "react";
import styles from "./styles.module.css";

const ToastDialog = forwardRef(({ onToastChange }, ref) => {
  const dialogRef = useRef(null);
  const [toastMessage, setToastMessage] = useState("");
  const [dialogTimeout, setDialogTimeout] = useState(0);
  const [dialogMode, setDialogMode] = useState(0);

  const sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  useImperativeHandle(ref, () => ({
    openDialog: async (message = "", timeout = 2500, delay = 0, mode = 0) => {
      if (delay > 0) {
        await sleep(delay);
      }

      if (message != "") {
        setToastMessage(message);
      }

      if (timeout > 0) {
        setDialogTimeout(timeout);
      }

      if (mode > 0) {
        setDialogMode(mode);
      }
      const element = dialogRef.current;
      if (element) {
        element.classList.remove(styles.dialogViewClose);
        element.classList.remove(styles.dialogViewHide);
        element.classList.add(styles.dialogViewActive);
      }
    },
  }));

  const closeDialog = () => {
    const element = dialogRef.current;
    if (element && element.classList.contains(styles.dialogViewActive)) {
      element.classList.remove(styles.dialogViewActive);
      element.classList.remove(styles.dialogViewClose);
      element.classList.add(styles.dialogViewHide);
      setDialogTimeout(0);
    }
  };

  const onAnimationEnd = () => {
    console.log("anim end");
    const element = dialogRef.current;
    if (element && element.classList.contains(styles.dialogViewHide)) {
      element.classList.remove(styles.dialogViewActive);
      element.classList.remove(styles.dialogViewHide);
      element.classList.add(styles.dialogViewClose);
      setDialogTimeout(0);
    }
  };

  useEffect(() => {
    if (dialogTimeout > 0) {
      setTimeout(() => closeDialog(), dialogTimeout);
    }
  }, [dialogTimeout]);

  return (
    <div
      className={[styles.dialogView, styles.dialogViewClose].join(" ")}
      onClick={() => closeDialog()}
      onAnimationEnd={onAnimationEnd}
      ref={dialogRef}
    >
      <div className={[styles.dialogContentsView]}>
        <div className={[styles.dialogContents]}>
          {dialogMode == 1 ? (
            <img src={require("../../media/icons/check_white_icon.png")} />
          ) : (
            <img src={require("../../media/icons/warning_white_icon.png")} />
          )}
          <div>{toastMessage}</div>
        </div>
      </div>
    </div>
  );
});

export default ToastDialog;
